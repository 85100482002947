<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';
import axios from 'axios';
import ClipboardJS from 'clipboard';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// 因為改變 delimiters 出現的問題
import {
  // eslint-disable-next-line no-unused-vars
  getDiff, questionType, toolbarOptions, modulesOptions, postMessage, actionPermission
} from '../methods';
import EditQuestion from './Detail/EditQuestion.vue';

const emit = defineEmits(['changeView']);
const props = defineProps({
  id: {
    type: Number,
    default: null,
  },
  token: {
    type: String,
    default: '',
  },
  isLink: {
    type: Boolean,
    default: false,
  },
});
const question = ref({});
const isLoading = ref(false);
isLoading.value = true;
const getQuestionDetail = (id) => {
  axios.get(`/v1/questions/${id}`).then((res) => {
    question.value = res.data.question;
    isLoading.value = false;
  }).catch((error) => {
    isLoading.value = false;
    postMessage('danger', '取得問題詳細內容時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  });
};
const currentUrl = ref('');
onMounted(() => {
  new ClipboardJS('.hexschool-copy');
  getQuestionDetail(props.id);
  currentUrl.value = window.location.href;
});

const replayIsLoading = ref(false); // 回覆區塊的 Loading
const questionEditStatus = ref(false); // 問題是否有變動
// 回覆問題
const editor = ref(null);
const replyContent = ref('');
const replyQuestion = () => {
  replayIsLoading.value = true;
  axios
    .post(`/v1/questions/${props.id}/replies`, {
      body: replyContent.value,
    })
    .then(() => {
      editor.value.setHTML('');
      replayIsLoading.value = false;
      getQuestionDetail(props.id);
      questionEditStatus.value = true;
    });
};
// 編輯回覆
const editReply = ref({
  show: false,
  replyId: null,
  body: '',
});
const closeEditReply = () => {
  editReply.value = {
    show: false,
    replyId: null,
    body: '',
  };
};
const startEditReply = (reply) => {
  editReply.value.show = true;
  editReply.value.replyId = reply.id;
  editReply.value.body = reply.body;
};
const submitEditReply = () => {
  replayIsLoading.value = true;
  axios
    .put(`/v1/questions/${props.id}/replies/${editReply.value.replyId}`, {
      body: editReply.value.body,
    })
    .then(() => {
      replayIsLoading.value = false;
      editReply.value = {
        show: false,
        replyId: null,
        body: '',
      };
      getQuestionDetail(props.id);
    });
};
// 刪除回覆
const deleteReply = (id) => {
  replayIsLoading.value = true;
  const text = '確認刪除這則回覆，刪除後就無法恢復。';
  if (confirm(text) === true) {
    axios.delete(`/v1/questions/${props.id}/replies/${id}`).then((res) => {
      if (res.data.message === '成功') {
        getQuestionDetail(props.id);
        replayIsLoading.value = false;
      } else {
        replayIsLoading.value = false;
        alert('刪除回覆失敗。');
      }
    });
  } else {
    replayIsLoading.value = false;
  }
};

// 編輯問題
const editState = ref({
  show: false,
  data: {},
});
const closeEdit = (refresh) => {
  editState.value.show = false;
  if (refresh) {
    getQuestionDetail(props.id);
    questionEditStatus.value = true;
  }
};
const editQuestion = (questionContent) => {
  editState.value.show = true;
  editState.value.data = questionContent;
};
// 刪除問題
const deleteQuestion = (id) => {
  const text = '確認刪除這則問題，刪除後就無法恢復。';
  if (confirm(text) === true) {
    axios.delete(`/v1/questions/${id}`).then((res) => {
      if (res.data.message === '成功') {
        emit('changeView', { type: 'list', id: -1, refresh: true });
      } else {
        alert('刪除問題失敗。');
      }
    });
  }
};

// 對問題按讚、取消讚
const questionUpvote = (method, qId) => {
  const result = method === 'post';
  axios[method](`/v1/questions/${qId}/upvote`).then((res) => {
    if (res.data.message === '成功') {
      if (result) {
        question.value.upvotes += 1;
      } else {
        question.value.upvotes -= 1;
      }
      question.value.is_upvote = result;
      questionEditStatus.value = true;
    }
  });
};
// 對回覆按讚、取消讚
const replyUpvote = (method, rId) => {
  const result = method === 'post';
  const findIndex = question.value.replies.findIndex((r) => r.id === rId);
  axios[method](`/v1/questions/${props.id}/replies/${rId}/upvote`).then((res) => {
    if (res.data.message === '成功') {
      if (result) {
        question.value.replies[findIndex].upvotes += 1;
      } else {
        question.value.replies[findIndex].upvotes -= 1;
      }
      question.value.replies[findIndex].is_upvote = result;
    }
  });
};

// 設定最佳解答
const bestAnswer = (method, rId) => {
  const result = method === 'post';
  const findIndex = question.value.replies.findIndex((r) => r.id === rId);
  axios[method](`/v1/questions/${props.id}/replies/${rId}/best-answer`).then((res) => {
    if (res.data.message === '成功') {
      if (result) {
        question.value.replies[findIndex].is_best_answer += 1;
      } else {
        question.value.replies[findIndex].is_best_answer -= 1;
      }
    }
  }).catch(err => {
    postMessage('danger', '最佳解答設定失敗', `${err.response.data.message}`);
  });
};
</script>

<template>
  <p>
    <a href="#" class="text-dark text-decoration-none d-flex align-items-center" @click.prevent="
      $emit('changeView', { type: 'list', id: -1, refresh: questionEditStatus ? true : false })
      "><span class="material-icons align-bottom me-1"> arrow_back </span>
      返回問題列表</a>
  </p>
  <!-- 主問題 -->
  <div class="border border-dark p-3 my-3 position-relative" style="min-height: 200px">
    <loading :active="isLoading" :is-full-page="false" />
    <div v-if="editState.show" class="">
      <EditQuestion :data="editState.data" @close-edit="closeEdit" />
    </div>
    <div v-else-if="question.title" class="d-flex align-items-start">
      <img :src="question.avatar ||
      'https://s.gravatar.com/avatar/9024444d63ae53512c40d2a60da3d6a3?d=mm'
      " alt="" width="48" height="48" style="object-fit: cover" class="rounded-circle">
      <div class="mt-2 mx-3 w-75">
        <h5 class="mb-1 hexschool-copy" title="點一下複製問題連結" style="cursor: pointer;"
          :data-clipboard-text="`${currentUrl}?questions_id=${question.id}`">
          ${question.title}
        </h5>
        <small class="text-muted">${question.nickname} / ${getDiff(question.create_time)} /
          ${question.video_course_lecture_name} /
          ${questionType(question.question_type_name)}</small>
        <div class="mt-4 hexschool-q-a-content">
          <div v-html="question.body" />
        </div>
      </div>
      <div class="ms-auto">
        <div class="d-flex">
          <small class="d-flex align-items-center me-2">
            ${question.upvotes}
            <span v-if="!actionPermission || question.from === 'udemy'"
              class="ms-1 material-icons">thumb_up_off_alt</span>
            <a v-else-if="!question.is_upvote" href="#" class="link-dark"
              @click.prevent="questionUpvote('post', question.id)">
              <span class="ms-1 material-icons"> thumb_up_off_alt </span>
            </a>
            <a v-else href="#" class="link-dark" @click.prevent="questionUpvote('delete', question.id)">
              <span class="ms-1 material-icons-sharp">
                thumb_up_off_alt
              </span>
            </a>
          </small>
          <small class="d-flex align-items-center">
            ${question.replies.length} <span class="ms-1 material-icons"> chat_bubble_outline </span>
          </small>
          <div
            v-if="actionPermission && token && question.is_author && (question.replies && question.replies.length === 0) && question.from !== 'udemy'"
            class="btn-group position-relative ms-2">
            <button type="button" class="btn btn-sm btn-hex-secondary" data-bs-toggle="dropdown"
              data-bs-display="static" aria-expanded="false">
              ...
            </button>
            <ul class="dropdown-menu dropdown-menu-end hex-dropdown-transform-none">
              <li class="hex-dropdown-item-margin-0">
                <button class="dropdown-item" type="button" @click="editQuestion(question)">
                  編輯
                </button>
              </li>
              <li class="hex-dropdown-item-margin-0">
                <button class="dropdown-item" type="button" @click="deleteQuestion(question.id)">
                  刪除
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 回覆 -->
  <p v-if="question.replies" class="text-muted">
    共有 ${ question.replies.length } 則回應
  </p>
  <div class="position-relative">
    <loading :active="replayIsLoading" :is-full-page="false" />
    <div v-for="reply in question.replies" :key="reply.id" class="p-3 border-bottom"
      :class="{ 'bg-light': reply.is_assistant_reply || reply.assistant_title }">
      <div class="d-flex align-items-start">
        <img :src="reply.avatar ||
      'https://s.gravatar.com/avatar/9024444d63ae53512c40d2a60da3d6a3?d=mm'
      " alt="" width="48" height="48" style="object-fit: cover" class="rounded-circle">
        <div class="mt-2 mx-3 w-75">
          <h5 class="mb-1">
            ${ reply.nickname }
            <small :style="{ color: reply.assistant_color_code }">${ reply.assistant_title }</small>
          </h5>
          <small class="text-muted">${getDiff(reply.create_time)}</small>
          <div v-if="reply.is_best_answer === 1" class="my-2">
            <span class="p-1 bg-hex-warning" style="font-size: 14px;">標記最佳解答</span>
          </div>
          <div class="mt-4 hexschool-q-a-content">
            <!-- 回覆編輯 -->
            <div v-if="editReply.show && editReply.replyId === reply.id" class="">
              <QuillEditor v-model:content="editReply.body" theme="snow" content-type="html" :toolbar="toolbarOptions"
                :modules="modulesOptions" />
              <div class="my-3 d-flex justify-content-between">
                <button class="btn btn-light px-4" @click="closeEditReply">
                  取消
                </button>
                <button class="btn btn-hex-primary px-4" :disabled="editReply.body === '' || editReply.body === '<p><br></p>' || isLoading || replayIsLoading" @click="submitEditReply">
                  送出
                </button>
              </div>
            </div>
            <!-- 回覆編輯 end -->
            <div v-else v-html="reply.body" />
          </div>
        </div>
        <div class="ms-auto text-nowrap">
          <div class="d-flex align-items-center">
            <small class="d-flex align-items-center">
              ${reply.upvotes}
              <span v-if="!actionPermission || question.from === 'udemy'"
                class="ms-1 material-icons">thumb_up_off_alt</span>
              <a v-else-if="!reply.is_upvote" href="#" class="link-dark" @click.prevent="replyUpvote('post', reply.id)">
                <span class="ms-1 material-icons"> thumb_up_off_alt </span>
              </a>
              <a v-else href="#" class="link-dark" @click.prevent="replyUpvote('delete', reply.id)">
                <span class="ms-1 material-icons-sharp">
                  thumb_up_off_alt
                </span>
              </a>
            </small>
            <div v-if="question.from !== 'udemy' && ((question.is_author && (reply.is_assistant_reply || reply.assistant_title)) || reply.is_author) && actionPermission" class="btn-group position-relative ms-2">
              <button type="button" class="btn btn-sm btn-hex-secondary" data-bs-toggle="dropdown"
                data-bs-display="static" aria-expanded="false">
                ...
              </button>
              <ul class="dropdown-menu dropdown-menu-end hex-dropdown-transform-none">
                <li v-if="question.is_author && (reply.is_assistant_reply || reply.assistant_title)"
                  class="hex-dropdown-item-margin-0">
                  <button v-if="reply.is_best_answer === 0" class="dropdown-item" type="button"
                    @click.prevent="bestAnswer('post', reply.id)">
                    最佳解答
                  </button>
                  <button v-else class="dropdown-item" type="button" @click.prevent="bestAnswer('delete', reply.id)">
                    取消最佳解答
                  </button>
                </li>
                <li v-if="reply.is_author" class="hex-dropdown-item-margin-0">
                  <button class="dropdown-item" type="button" @click="startEditReply(reply)">
                    編輯
                  </button>
                </li>
                <li v-if="reply.is_author" class="hex-dropdown-item-margin-0">
                  <button class="dropdown-item" type="button" @click="deleteReply(reply.id)">
                    刪除
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 回覆 end-->
  <!-- Editor -->
  <div class="mt-3">
    <div v-if="question.from === 'udemy'">
      <p class="text-center text-muted">此問題來自 Udemy，無法進行回覆</p>
    </div>
    <div v-else-if="!props.isLink">
      <p class="text-center text-muted">請先綁定 RPG 帳號後，即可回覆此問題</p>
    </div>
    <div v-else-if="actionPermission">
      <QuillEditor ref="editor" v-model:content="replyContent" theme="snow" content-type="html"
        :toolbar="toolbarOptions" :modules="modulesOptions" />
      <div class="my-3 d-flex justify-content-end">
        <button class="btn btn-hex-primary px-4"
          :disabled="replyContent === '' || isLoading || replayIsLoading || !actionPermission" @click="replyQuestion">
          送出
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
