<template>
  <div
    v-if="user.teachable_id && user.teachable_id !== '' && qaAreaShow"
    class="container py-4 hexschool-q-a"
  >
    <Toasts />
    <Notification v-if="token && listStatus" :message-id="notificationParams" />
    <WeekendModal
      v-if="isWeekendModalShow"
      @close="closeWeekendModal"
    />
    <NotifyModal
      v-if="isNotifyModalShow"
      @close="closeNotifyModal"
      @turn-off="notifyNotShowAgain"
    />
    <keep-alive>
      <QusList
        v-if="viewNow === 'list'"
        :token="token"
        :refresh="refreshStatus"
        :is-link="isLink"
        @changeView="changeView"
        @get-ok="getListOk"
      />
    </keep-alive>
    <AskNewQus
      v-if="viewNow === 'new'"
      @changeView="changeView"
    />
    <QusDetail
      v-if="viewNow === 'detail'"
      :id="questionId"
      :token="token"
      :is-link="isLink"
      @changeView="changeView"
    />
    <!-- <HelloWorld msg="Hello Vue 3 + Vite" /> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import QusDetail from './components/QusDetail';
import AskNewQus from './components/AskNewQus';
import QusList from './components/QusList';
import WeekendModal from './components/WeekendModal';
import NotifyModal from './components/NotifyModal.vue';
import Notification from './components/Notification';
import Toasts from './components/Toasts';
import { getCookie, postMessage, directToRPGUserSystem, saveTokenToCookie, checkActionPermission, qaAreaShow } from './methods';

// 問答列表取得成功才顯示通知
const listStatus = ref(false);
const getListOk = () => {
  listStatus.value = true;
};

// 假日 / 助教休息中 Modal 顯示
const isWeekendModalShow = ref(false);
const isWeekend = () => { // 確認是否是假日
  const dt = new Date();
  const dtMonth = dt.getMonth();
  const dtDay = dt.getDate();
  const isWeeekend = (dt.getDay() === 6 || dt.getDay() === 0);
  const weekendData = JSON.parse(window.localStorage.getItem('h_weekend_notice')) || [];
  const today = (new Date(weekendData.date).getMonth() !== dtMonth || new Date(weekendData.date).getDate() !== dtDay);
  isWeekendModalShow.value = isWeeekend && today; // 今天是假日，且沒有儲存過資料
};
isWeekend();
const closeWeekendModal = (value) => {
  isWeekendModalShow.value = value;
  const modalStatus = {
    show: false,
    date: new Date().getTime(),
  };
  window.localStorage.setItem('h_weekend_notice', JSON.stringify(modalStatus));
};

// 通知系統更新 modal
const isNotifyModalShow = ref(false);
const isNotify = () => { //
  const dt = new Date();
  const dtMonth = dt.getMonth();
  const dtDay = dt.getDate();
  // 今日不再顯示
  const notifyStatus = JSON.parse(window.localStorage.getItem('h_system_notice')) || [];
  const today = (new Date(notifyStatus.date).getMonth() !== dtMonth || new Date(notifyStatus.date).getDate() !== dtDay);
  isNotifyModalShow.value = today;
};
const closeNotifyModal = (value) => {
  isNotifyModalShow.value = value;
};
const notifyNotShowAgain = (check) => {
  if (check) {
     const modalStatus = {
    show: false,
    date: new Date().getTime(),
  };
  window.localStorage.setItem('h_system_notice', JSON.stringify(modalStatus));
  }
 
};

// 登入相關
const isLink = ref(false); // 是否綁定 RPG（is_link）
const token = ref('');
const user = ref({
  teachable_id: '',
  email: '',
});
const login = () => {
  axios.post(`${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_USER_SYSTEM_URL : ''}/v1/user/login/teachable`, {
    teachable_id: user.value.teachable_id || '',
    email: user.value.email || '',
  }).then((res) => {
    const tokenExp = res.data.expiration;
    /* API 回傳的 access_token token 是 teachable 暫時的 token，不是 RPG 的 token
       如果已經綁定 RPG，則需要導向 RPG 登入頁面並 redirect 回來
    */
    isLink.value = res.data.is_link;
    if (!res.data.is_link) { // 未綁定過，直接使用 teachable token
      saveTokenToCookie(tokenExp, res.data.access_token, 'teachable', 'false');
      token.value = res.data.access_token;
    } else {
      // 已綁定過 
      if (['production', 'development'].includes(process.env.VUE_APP_ENV)) {
        directToRPGUserSystem(true);
      } else {
        saveTokenToCookie(tokenExp, res.data.access_token, 'teachable', 'true');
        token.value = res.data.access_token;
      }
    }
      
    if (questionParams.value) {
      changeView({type: 'detail', id: questionParams.value, refresh: false})
    }
  }).catch((error) => {
    postMessage('danger', '取得使用者發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  });
};
onMounted(() => {
  const userInfo = document.querySelector('#fedora-data');
  questionParams.value = new URL(location.href).searchParams.get('questions_id');
  notificationParams.value = new URL(location.href).searchParams.get('message_id');
  // 串接回來的訊息
  // https://courses.hexschool.com/courses/bc2694/lectures/40189606?msg=成功&state=success&access_token=token&exp=過期時間
  const paramsMsg = new URL(location.href).searchParams.get('msg');
  const paramsType = new URL(location.href).searchParams.get('state');
  if (paramsType && paramsMsg) {
    const mapState = {
      success: {
        type: 'success',
        title: '成功',
      },
      error: {
        msgType: 'danger',
        title: '失敗',
      },
    }
    postMessage(mapState[paramsType]?.type, mapState[paramsType]?.title, paramsMsg)
    if (paramsType === 'success') {
      const paramsToken = new URL(location.href).searchParams.get('access_token');
      const expiration = new URL(location.href).searchParams.get('exp');
      saveTokenToCookie(expiration, paramsToken, 'rpg', 'true');
      token.value = paramsToken;
    }
  }

  if (userInfo && userInfo.dataset.id) {
    user.value.teachable_id = userInfo.dataset.id;
    user.value.email = userInfo.dataset.email;
    isLink.value = getCookie('rpg_temp_token_is_link') === 'true';
    const tokenInCookie = getCookie('rpg_temp_token');
    checkActionPermission();
    if (tokenInCookie && tokenInCookie !== "") {
      axios.defaults.headers.common.Authorization = `Bearer ${tokenInCookie}`;
      token.value = tokenInCookie;
      isLink.value = getCookie('rpg_temp_token_is_link') === 'true';
      
      if (!isLink.value) { // 沒綁定過的話，顯示改版通知
        isNotify();
      }
      if (questionParams.value) {
        changeView({type: 'detail', id: questionParams.value, refresh: false})
      }
    } else {
      login();
    }
  }

  // 錯誤處理
  const errorParams = new URL(location.href).searchParams.get('errorType');
  const errorMsg = new URL(location.href).searchParams.get('errorMsg');
  if (errorParams && errorMsg) {
    postMessage('danger', '登入 RPG 帳號失敗', `${errorMsg}`);
  }
});

// 當前看到的畫面
const viewNow = ref('list'); // 當前看到的畫面：問題列表(list)、問題詳細(detail)、新問題(new)
const refreshStatus = ref(false); // 當前看到的畫面：問題列表(list)、問題詳細(detail)、新問題(new)
const questionParams = ref(null); // questions_id
const notificationParams = ref(null); // message_id
const questionId = ref(-1); // 當前看到的畫面：問題列表(list)、問題詳細(detail)、新問題(new)
const changeView = ({ type, id, refresh }) => {
  viewNow.value = type;
  questionId.value = id;
  refreshStatus.value = refresh;
};
</script>

<style lang="scss">
@import './assets/scss/all.scss';
</style>import { includes } from 'core-js/core/array';

