<script setup>
import { onMounted } from 'vue';
import lottie from 'lottie-web';

onMounted(() => {
  lottie.loadAnimation({
    container: document.getElementById('svgContainer'),
    animType: 'svg',
    loop: true,
    autoplay: true,
    path: 'https://raw.githubusercontent.com/Tzuhui/test-view/main/Reply-Modal.json',
  });
});

</script>

<template>
  <div class="weekend-modal-bg">
    <div class="weekend-modal">
      <div class="d-flex flex-column align-items-center justify-content-center text-center">
        <div id="svgContainer" />
        今天是假日，助教正在休息中<br>平日會再批改您的作業與問題哦～
      </div>
      <div class="d-flex align-items-center justify-content-center my-3">
        <button
          class="btn btn-hex-primary px-4 rounded-0"
          @click="$emit('close')"
        >
          OK，沒問題
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.weekend-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  min-height: 550px;
  background-color: rgba(0, 0, 0, 0.4);
}
.weekend-modal {
  background-color: white;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 65%;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 1rem;
}
</style>
