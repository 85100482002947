import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import 'bootstrap';
import { checkDomainENV, getCookie, postMessage, handleQANoShow, reLogin } from './methods';

function generateRandomString(num) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result1 = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i += 1) {
    result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result1;
}

const domain = checkDomainENV();
if (!getCookie('xsrf-token')) {
  document.cookie = `xsrf-token=${generateRandomString(32)};${process.env.NODE_ENV === 'production' ? domain : null
    }`;
}

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_URL : '';
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'xsrf-token';
axios.defaults.xsrfCookieName = 'xsrf-token';
// 統一結果處理
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const errorData = error.response.data;
      const errorCode = errorData?.code || '';
      const errorStatus = error.response.status;
      if (errorStatus === 401) {
        reLogin();
      }
      if (errorCode !== '') {
        if (errorCode === 'NO_QA') {
          handleQANoShow();
        }
        if (errorCode === 'GO_LOGIN') {
          reLogin();
        }
      } else {
        postMessage('danger', '出現錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
      }
    }

  }
);

const app = createApp(App);
app.mount('#newHexRPG_Teachable');
