<script setup>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {
  ref, reactive, onMounted, watch, defineEmits, defineProps,
} from 'vue';
import Pagination from 'v-pagination-3';

// 因為改變 delimiters 出現的問題
// eslint-disable-next-line no-unused-vars
import { getDiff, questionType, directToRPGUserSystem, checkDomainENV, postMessage, actionPermission, getCookie } from '../methods';

const emit = defineEmits(['changeView', 'getOk']);
const props = defineProps({
  token: {
    type: String,
    default: '',
  },
  isLink: Boolean,
  refresh: Boolean,
});

const isLoading = ref(false);
const readNotice = ref(false); // 讀過發問規範
watch(readNotice, (value) => {
  window.localStorage.setItem('hex_teachable_notice_read', value);
});

const data = reactive({
  questions: [],
  pagination: {},
  courseQuestionCount: 300,
});

const courseId = ref('');
const videoCourseId = ref('');

const getQuestionList = (page = 1) => {
  isLoading.value = true;
  axios.get(`/v1/questions/video-courses/${videoCourseId.value}/lectures/${courseId.value}?page=${page}`).then((res) => {
    data.questions = res.data.questions;
    data.pagination = res.data.pagination;
    data.courseQuestionCount = res.data.courseQuestionCount;
    isLoading.value = false;
    emit('getOk');
  }).catch(() => {
    isLoading.value = false;
  });
};

onMounted(() => {
  isLoading.value = true;
  courseId.value = document.getElementById('lecture_heading').dataset.lectureId;
  videoCourseId.value = document.getElementById('lecture_heading').dataset.lectureUrl.split('/')[2];
  readNotice.value = window.localStorage.getItem('hex_teachable_notice_read') === 'true';
  getQuestionList();
});
watch(() => props.refresh, (selection) => {
  if (selection) {
    getQuestionList();
    emit('changeView', { type: 'list', id: -1, refresh: false });
  }
});
watch(() => props.token, (value) => {
  if (value) {
    getQuestionList();
  }
});

const splitString = (body) => {
  const regex = /(<([^>]+)>)/gi;
  const results = body.replace(regex, '');
  return `${results.slice(0, 60)}...`;
};

const getCurrentTwentyData = () => {
  axios.get(`/v1/questions/video-courses/${videoCourseId.value}`).then((res) => {
    data.questions = res.data.questions;
    data.pagination = {};
  }).catch(error => {
    postMessage('danger', '取得最新 20 筆資料時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  });
};

const currentMenu = ref('chapter'); // 目前講座: chapter, 最近二十筆 recent
watch(() => currentMenu.value, (value) => {
  if (value === 'chapter') {
    getQuestionList();
  } else {
    getCurrentTwentyData();
  }
});

const openLoginUrl = () => {
  const isLink = getCookie('rpg_temp_token_is_link') === 'true';
  if (isLink) {
    directToRPGUserSystem(true);
  } else {
    directToRPGUserSystem(false);
  }
}
</script>

<template>
  <div class="d-flex align-items-start">
    <div class="d-flex">
      <h2 class="text-nowrap mb-0 me-3">
        問題列表
      </h2>
      <select
        v-model="currentMenu"
        class="form-select"
        aria-label="Default select example"
      >
        <option value="chapter">
          目前講座
        </option>
        <option value="recent">
          此課程最新 20 筆
        </option>
      </select>
    </div>
    <div class="ms-auto d-flex align-items-start">
      <div class="d-flex flex-column">
        <button
          class="btn btn-hex-primary px-4 rounded-0"
          :disabled="!readNotice || !actionPermission"
          @click="$emit('changeView', {type: 'new', id: -1, refresh: false })"
        >
          提出新問題
        </button>
        <div class="form-check mt-2">
          <input
            id="readNoticeCheckbox"
            v-model="readNotice"
            class="form-check-input"
            type="checkbox"
            value=""
          >
          <label
            class="form-check-label"
            for="readNoticeCheckbox"
          >
            我已詳讀<a
              href="https://www.notion.so/94eb3ec6f7c54492a5878a0dc58c74e1"
              target="_blank"
            >發問規範</a>
          </label>
        </div>
      </div>
      <button
        v-if="!actionPermission"
        @click="openLoginUrl"
        class="btn btn-dark px-4 rounded-0 ms-2"
      >
        <span v-if="!props.isLink">綁定</span>
        <span v-else>登入</span>
        RPG 帳號
      </button>
    </div>
  </div>
  <p class="my-3 text-muted">
    此章節問題數 ${data.pagination.total} / 此課程問題數 ${ data.courseQuestionCount }
  </p>
  <div class="position-relative">
    <loading
      :active="isLoading"
      :is-full-page="false"
    />
    <div
      class="list-group"
      style="min-height: 200px;"
    >
      
      <a
        v-for="q in data.questions"
        :id="q.id"
        :key="q.id"
        href="#"
        class="list-group-item list-group-item-action h-list-group-item p-3"
        aria-current="true"
        @click.prevent="$emit('changeView', {type: 'detail', id: q.id, refresh: false})"
      >
        <div class="d-flex align-items-start">
          <img
            :src="q.avatar || 'https://s.gravatar.com/avatar/9024444d63ae53512c40d2a60da3d6a3?d=mm'"
            alt=""
            width="48"
            height="48"
            style="object-fit: cover"
            class="rounded-circle"
          >
          <div class="mt-2 mx-3">
            <h5 class="mb-1">${ q.title }</h5>
            <div
              class="mb-4 text-muted"
              v-html="splitString(q.body)"
            />
            <small class="text-muted">${ q.nickname } / ${getDiff(q.create_time)} / ${q.video_course_lecture_name} / ${ questionType(q.question_type_name) }</small>
          </div>
          <div class="ms-auto text-nowrap">
            <small class="d-flex align-items-center">
              ${q.replies} <span class="ms-1 material-icons">
                chat_bubble_outline
              </span>
            </small>
            <small class="d-flex align-items-center mt-2">
              ${q.upvotes} <span class="ms-1 material-icons">
                thumb_up_off_alt
              </span>
            </small>
          </div>
        </div>
      </a>
    </div>
    <div
      v-if="currentMenu === 'chapter' && data.pagination && data.pagination.current_page"
      class="my-3"
    >
      <Pagination
        v-model="data.pagination.current_page"
        :records="parseInt(data.pagination.total)"
        :per-page="parseInt(data.pagination.count)"
        :hide-count="true"
        @paginate="getQuestionList"
      />
    </div>
  </div>
</template>

<style scoped>
</style>import { get } from 'core-js/core/dict';

