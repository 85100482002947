<script setup>
import { ref } from 'vue';
// import lottie from 'lottie-web';
const readNotice = ref(false);
// onMounted(() => {
//   lottie.loadAnimation({
//     container: document.getElementById('svgContainer'),
//     animType: 'svg',
//     loop: true,
//     autoplay: true,
//     path: 'https://raw.githubusercontent.com/Tzuhui/test-view/main/Reply-Modal.json',
//   });
// });

</script>

<template>
  <div class="notify-modal-bg">
    <div class="notify-modal">
      <h2 class="text-center">我們改版囉！</h2>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <p class="my-3">
            RPG 平台為六角學院為了提升學員學習成效而建置的平台。<br/>
            為了提升用戶體驗並打造更好的學習平台，RPG 平台功能於 2024 年全新改版優化。<br/><br/>
            全新改版的 RPG 平台除了維持任務管理、追蹤學員作業繳交狀況外，為了方便學員觀看課程更加便利，我們將兩個平台 Teachable 與 RPG 帳號整合在一起了！<br/><br/>
            因此使用上需先在 RPG 平台綁定 Teachable 帳號，以獲得最佳課程學習體驗哦！<br/>綁定流程可以參考<a href="https://hackmd.io/3jsLGGuySc2w8jpJi2pMXg?view" target="_blank">此操作手冊說明</a>。<br/><br/>
            在此也謝謝同學一路支持六角，
            因為有您才能讓六角持續提供更好的服務，
            讓我們一起共同打造優質的學習平台！
            </p>
            <div class="form-check mt-2">
              <input
                id="readNoticeCheckbox"
                class="form-check-input"
                type="checkbox"
                v-model="readNotice"
                value=""
                @change="$emit('turn-off', readNotice)"
              >
              <label
                class="form-check-label"
                for="readNoticeCheckbox"
              >
                今天不再顯示
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center my-3">
        <button
          class="btn btn-hex-primary px-4 rounded-0"
          @click="$emit('close', false)"
        >
          繼續發問
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.notify-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10090;
  min-height: 550px;
  height: 100vh;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.notify-modal {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 90%;
  max-width: 600px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 1rem;
}
</style>
