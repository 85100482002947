<template>
  <div aria-live="polite" aria-atomic="true" class="position-relative">
    <div class="toast-container end-0 position-fixed p-3"
      style="min-width: 180px; z-index: 1060; bottom: 80px">
      <div
        v-for="msg in toastsMessages" :key="msg.id" class="toast show"
        role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header" :class="msg.statusBg">
          <strong class="mr-auto" :class="msg.statusColor">
            ${ msg.title }
          </strong>
        </div>
        <div class="toast-body">
          ${ msg.message }
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { toastsMessages } from '../methods';

export default {
  name: 'Toast',
  setup() {
    watch(toastsMessages, () => {}, { deep: true });
    return {
      toastsMessages,
    };
  },
};
</script>
