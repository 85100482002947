<script setup>
import { defineProps } from 'vue';
const props = defineProps({
  reviewData: {
    type: Object,
    default: null,
  },
  reviewType: {
    type: String,
    default: "",
  }
});
</script>

<template>
  <div class="review-modal-bg">
    <div class="review-modal">
      <div class="px-4">
        <h3 class="text-center">${props.reviewType === 'video_course_review_reply'? '您給予課程的公開評價' :  '您給予講師的悄悄話'}</h3>
        <p class="text-muted mb-1">您的留言</p>
        <div class="">${ props.reviewType === 'video_course_review_reply'? props.reviewData.content : props.reviewData.whisper.content }</div>
        <hr />
        <div v-if="props.reviewType === 'video_course_review_reply' && props.reviewData.reply?.content">
          <p class="text-muted mb-1">六角團隊的回覆</p>
          <div class="">${ props.reviewData.reply.content }</div>
        </div>
        <div v-if="props.reviewType === 'video_course_review_whisper_reply' && props.reviewData.whisper.reply?.content">
          <p class="text-muted mb-1">六角團隊的回覆</p>
          <div class="">${ props.reviewData.whisper.reply.content }</div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3 px-4">
        <button
          class="btn btn-hex-primary px-4 rounded-0"
          @click="$emit('close')"
        >
          關閉
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.review-modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  min-height: 550px;
  background-color: rgba(0, 0, 0, 0.4);
}
.review-modal {
  background-color: white;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 65%;
  max-width: 550px;
  height: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0.25rem;
}
</style>
