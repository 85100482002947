import axios from 'axios';
import ImageUploader from 'quill-image-uploader';

import { reactive, ref } from 'vue';

export const qaAreaShow = ref(true); // 正常情況問答區預設顯示，若有遇到錯誤，則隱藏問答區並且顯示錯誤訊息
export const handleQANoShow = () => {
  qaAreaShow.value = false;
}

export const userData = reactive({
  teachable_id: '',
  email: '',
});

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2 || parts.length > 2)
    return parts.pop().split(';').shift();
}

// 確認可發問、按讚、回答的狀態：綁定 rpg 且目前的 token 是 rpg
export const actionPermission = ref(false);
export const checkActionPermission = () => { // true: 可以操作，false: 不可操作
  const tokenType = getCookie('rpg_temp_token_type');
  const isLink = getCookie('rpg_temp_token_is_link');
  const token = getCookie('rpg_temp_token');
  if (tokenType === 'rpg' && isLink === 'true' && token) {
    actionPermission.value = true;
  } else {
    actionPermission.value = false; // 先修改為 true，之後再改回 false
  }
};

export const toastsMessages = reactive([]);

export function postMessage(type, title, message) {
  const object = {
    statusBg: type === 'danger' ? 'bg-danger' : 'bg-success',
    statusColor: ' text-white',
    title,
    message,
  };
  toastsMessages.push(object);
  setTimeout(() => {
    toastsMessages.shift();
  }, 5000);
}

export function checkDomainENV() {
  if (process.env.VUE_APP_ENV === 'development') {
    // 問答統一用 .com
    return 'domain=.hexschool.com;path=/;';
  }
  return 'domain=.hexschool.com;path=/;';
}

// 將 token 存入 cookie
export function saveTokenToCookie(expiration, token, tokenType, isLink) {
  const domain = checkDomainENV();
  const date = new Date();
  const expirationTimeStamp = expiration ? expiration * 1000 : date.getTime() + (20 * 24 * 60 * 60 * 1000)
  date.setTime(expirationTimeStamp);

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  document.cookie = `rpg_temp_token=${token};expires=${date.toGMTString()};${['production', 'development'].includes(process.env.VUE_APP_ENV) ? domain : null}`;
  document.cookie = `rpg_temp_token_type=${tokenType};expires=${date.toGMTString()};${['production', 'development'].includes(process.env.VUE_APP_ENV) ? domain : null}`;
  document.cookie = `rpg_temp_token_is_link=${isLink};expires=${date.toGMTString()};${['production', 'development'].includes(process.env.VUE_APP_ENV) ? domain : null}`;
}

export function getDiff(date) {
  const timestamp = new Date(date).getTime() / 1000;
  let mistiming = Math.round(new Date() / 1000) - timestamp;
  const postfix = mistiming > 0 ? '前' : '後';
  mistiming = Math.abs(mistiming);
  const timeArray = ['年', '個月', '星期', '天', '小時', '分鐘', '秒'];
  const timeSec = [31536000, 2592000, 604800, 86400, 3600, 60, 1];

  for (let i = 0; i < 7; i += 1) {
    const inm = Math.floor(mistiming / timeSec[i]);
    if (inm !== 0) {
      return `${inm} ${timeArray[i]}${postfix}`;
    }
  }
  return 'error';
}

export function questionType(type) {
  if (type === 'question') {
    return '課程問題';
  }
  if (type === 'homework') {
    return '作業繳交';
  }
  return '';
}

export function transformTypeToId(type) {
  if (type === 'question') {
    return 1;
  }
  if (type === 'homework') {
    return 2;
  }
  return '';
}

/**
 * Quill ToolBar
 */
export const toolbarOptions = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['link', 'code', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['image'],
  ['clean'], // remove formatting button
];

/**
 * Quill 圖片模組
 */
/**
 * 圖片格式判斷
 */
export const allowImageMime = (type) => {
  const allowImageType = {
    'image/png': 'png',
    'image/jpg': 'jpeg',
    'image/jpeg': 'jpeg',
  };
  return allowImageType[type];
};

/**
 * 封裝 FileReader
 */
export const readFileBuffer = (file) =>
  new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file);
    fr.onload = (e) => {
      const { result } = e.target;
      resolve(result);
    };
    fr.onerror = reject;
  });

export const modulesOptions = {
  name: 'ImageUploader',
  module: ImageUploader,
  options: {
    upload(file) {
      return new Promise((resolve, reject) => {
        const { type, size } = file;
        if (!allowImageMime(type)) {
          postMessage(
            'danger',
            '圖片格式錯誤',
            '僅限上傳 png、jpg 與 jpeg 檔案格式'
          );
          reject(new Error('僅限上傳 png、jpg 與 jpeg 檔案格式'));
          return;
        }

        const maxSize = 1024 * 1024 * 3;
        if (size >= maxSize) {
          postMessage('danger', '圖片過大', '圖片大小不得超過 3MB。');
          reject(new Error('圖片大小不得超過 3MB。'));
          return;
        }

        const imageApiUrl = `/v1/questions/upload-image-url/${allowImageMime(
          type
        )}`;
        const uploadImg = async () => {
          try {
            const buffer = await readFileBuffer(file);

            const { data } = await axios.get(imageApiUrl);

            const cacheImgUrl = data.image_url;

            const imageUploadApiUrl =
              process.env.NODE_ENV === 'production'
                ? data.upload_url
                : `/images.hexschool.io/qa${data.upload_url.split('qa')[1]}`;
            const config = {
              transformRequest(reqData, headers) {
                // eslint-disable-next-line no-param-reassign
                delete headers.common.Authorization;
                return reqData;
              },
              headers: {
                'Content-Type': type,
              },
              withCredentials: false,
            };
            await axios.put(imageUploadApiUrl, buffer, config);
            resolve(cacheImgUrl);
          } catch (error) {
            postMessage('danger', '圖片上傳失敗', '圖片上傳失敗，請重新上傳。');
            reject(new Error('圖片上傳失敗。'));
          }
        };

        uploadImg();
      });
    },
  },
};

export const directToRPGUserSystem = (bind) => {
  window.location.href = `${process.env.VUE_APP_USER_SYSTEM_DOMAIN}/#/login?bind=${bind}&redirectType=teachable&route=${window.location.pathname}/`;
};

// 當 api 有 401 錯誤或是 errorCode 為 GO_LOGIN 時，重新登入
export const reLogin = () => {
  const userInfo = document.querySelector('#fedora-data');
  if (userInfo && userInfo.dataset.id) {
    axios.post(`${process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_USER_SYSTEM_URL : ''}/v1/user/login/teachable`, {
      teachable_id: userInfo.dataset.id || '',
      email: userInfo.dataset.email || '',
    }).then((res) => {
      const tokenExp = res.data.expiration;
      if (!res.data.is_link) { // 未綁定過，直接使用 teachable token
        saveTokenToCookie(tokenExp, res.data.access_token, 'teachable', 'false');
        window.location.reload();
      } else {
        // 已綁定過 
        if (['production', 'development'].includes(process.env.VUE_APP_ENV)) {
          directToRPGUserSystem(true);
        } else {
          saveTokenToCookie(tokenExp, res.data.access_token, 'teachable', 'true');
        }
      }
    }).catch((error) => {
      console.log(error);
      postMessage('danger', '取得使用者發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
    });
  }
};