<script setup>
import axios from 'axios';
import {
  ref, onMounted, watchEffect, defineEmits
} from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

// 因為改變 delimiters 出現的問題
// eslint-disable-next-line no-unused-vars
import { questionType, toolbarOptions, modulesOptions, postMessage } from '../methods';

const emit = defineEmits(['changeView']);
const courseTitle = ref('');
const courseId = ref('');
const isLoading = ref(true);

const questionTypesList = ref({});
const getQuestionCategoryMenu = () => {
  axios.get('/v1/questions/selector/types').then((res) => {
    questionTypesList.value = res.data.question_types;
    isLoading.value = false;
  }).catch((error) => {
    isLoading.value = false;
    postMessage('danger', '取得問題分類時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
  });
};

const editorContent = ref(null);
const newQuestion = ref({
  title: '',
  question_type_id: 1,
  body: '',
});

const limitLength = (key, limit) => {
  if (newQuestion.value[key].length > limit) {
    newQuestion.value[key] = newQuestion.value[key].slice(0, limit);
  }
}

watchEffect(() => {
  if (newQuestion.value.title !== '') {
    const tempTitle = newQuestion.value.title || '';
    if (['繳交', '提交', '交作業'].some((word) => tempTitle.match(word))) {
      newQuestion.value.question_type_id = 2;
    }
  }
});
const convertDeltaToHTML = () => {
  const cfg = {};
  const converter = new QuillDeltaToHtmlConverter(editorContent.value.ops, cfg);
  newQuestion.value.body = converter.convert();
};
const submitNewQuestion = () => {
  convertDeltaToHTML();
  isLoading.value = true;
  if (newQuestion.value.body.length > 16800) {
    alert('問題內容過長（限制 16800 字），請刪除部分文字');
    isLoading.value = false;
    return;
  } else {
    axios.post(`/v1/questions/lectures/${courseId.value}`, newQuestion.value).then(() => {
      isLoading.value = false;
      emit('changeView', { type: 'list', id: -1, refresh: true });
    }).catch((error) => {
      isLoading.value = false;
      postMessage('danger', '提出新問題時發生錯誤', `${error?.response?.status} - ${error?.response?.data.message}`);
    })
  }
};
onMounted(() => {
  courseTitle.value = document.getElementById('lecture_heading').textContent.trim();
  courseId.value = document.getElementById('lecture_heading').dataset.lectureId;
  getQuestionCategoryMenu();
});
</script>

<template>
  <div class="mt-4">
    <a href="#" class="text-dark text-decoration-none d-flex align-items-center"
      @click.prevent="$emit('changeView', { type: 'list', id: -1, refresh: false })"><span
        class="material-icons align-bottom me-1">
        arrow_back
      </span> 返回問題列表</a>
    <div class="mt-3 position-relative">
      <loading :active="isLoading" :is-full-page="false" />
      <div class="d-flex align-items-center">
        <h4 class="mb-0">
          新增問題
        </h4>
        <p class="mb-0 bg-light text-muted h5 py-2 px-3 rounded-pill ms-2">
          ${courseTitle}
        </p>
      </div>
      <div class="my-3">
        <label for="title" class="form-label">問題標題 <small>(50 字內)</small></label>
        <input id="title" v-model="newQuestion.title" type="text" class="form-control" aria-describedby=""
          @blur="limitLength('title', 50)" @keydown="limitLength('title', 50)" placeholder="e.g. 作業繳交" />
      </div>
      <div class="mb-3">
        <label for="category" class="form-label">問題分類</label>
        <select id="category" v-model="newQuestion.question_type_id" class="form-select"
          aria-label="Default select example">
          <option v-for="qusType in questionTypesList" :key="qusType.name" :value="qusType.id">
            ${questionType(qusType.name)}
          </option>
        </select>
      </div>
      <div class="mt-3">
        <QuillEditor v-model:content="editorContent" theme="snow" :toolbar="toolbarOptions" :modules="modulesOptions" />
        <div class="my-3 d-flex justify-content-end">
          <button class="btn btn-hex-primary px-4"
            :disabled="newQuestion.title === '' || !editorContent || (typeof editorContent?.ops?.[0]?.insert === 'string' && editorContent?.ops?.[0]?.insert.trim() === '') || isLoading"
            @click="submitNewQuestion">
            送出
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
